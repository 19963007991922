Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config");
exports.baseURL = baseURL;
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.apiNews = "bx_block_data_import_export/fetch_feed";
exports.apiNewsLangauge = `https://newsapi.org/v2/everything?q={0}&apiKey=59e4aea4f23b41c892aea5865af0687a&language`;
exports.uploadPhotoApiEndPoint = "bx_block_ticket/tickets";
exports.FAQApi = "https://localvodsaudi-118668-ruby.b118668.dev.eastus.az.svc.builder.cafe//bx_block_static_content/faqs?language=";
exports.faqlistendpoint = "bx_block_static_content/faqs";
exports.getLandingPageDataURL = "bx_block_content_management/contents/get_content_data";
exports.getContinueWatchList = "bx_block_content_management/contents/get_continue_watch_histoty";
exports.getMovieDetail = "bx_block_content_management/contents";
exports.getPodcastDetail = "bx_block_content_management/audio_podcasts";
exports.podcastUrl = "bx_block_content_management/audio_podcasts";
exports.podcastFilterUrl = "bx_block_content_management/audio_podcasts/get_podcast_filter_data";
exports.trendingPodcastUrl = "bx_block_content_management/audio_podcasts/trending_podcast";
exports.podcastCategoryUrl = `bx_block_content_management/audio_podcasts/podcast_category`;
exports.getAlsoWatch = "bx_block_content_management/contents/similar_contents";
exports.getNextEpisode = "bx_block_content_management/audio_podcasts/next_podcast_episode";
exports.getEpisodeByID = "bx_block_content_management/episodes";
exports.createWatchHistory = "bx_block_content_management/contents/create_history";
exports.getPodcastComments = "bx_block_content_management/audio_podcasts/podcast_comments";
exports.comments = "bx_block_comments/comments";
exports.apiPostType = "POST";
exports.apiGetType = "GET";
exports.getStreamingUrl = "bx_block_content_management/contents/get_streaming_url";
exports.favourites = "bx_block_favourites/favourites";
exports.getMovieDetailsById = "bx_block_content_management/contents";
exports.getSimilarMoviesById ="bx_block_content_management/contents/similar_contents";
exports.createFavourites = "bx_block_favourites/favourites";
exports.getComments = "bx_block_comments/comments";
exports.castDataAPIEndPoint = "/bx_block_content_management/casts/";
exports.castDataAPIEndPoint = "/bx_block_content_management/casts";
exports.bandDataAPIEndPoints = "/bx_block_content_management/contents/get_content_data";
exports.bandFilterAPIEndPoints = "/bx_block_content_management/contents/get_content_filter_data";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.createComments = "bx_block_comments/comments";
exports.webSeriesAPIEndPoints = "/bx_block_content_management/episodes/web_series";
exports.seasonsAPIEndPoints = "/bx_block_content_management/episodes/get_episode";
exports.notificationListAPIEndPoints = "/bx_block_push_notifications/push_notifications";
exports.getUserProfileEndPoint = "bx_block_profile/profiles/user_profiles";
exports.getRefershTokenEndPoint = "account_block/accounts/refresh_token";
exports.categoryListAPIEndPoints = "/bx_block_categories/categories";
exports.createWatchHistoryAPIEndPoints = "/bx_block_content_management/contents/create_history";
exports.getContinueWatchDataAPIEndPoints = "/bx_block_content_management/contents/get_continue_watch_histoty";
exports.trendingPodcastListAPIEndPoints = "/bx_block_content_management/audio_podcasts/trending_podcast";
exports.addPodcastToFavouritesListAPIEndPoints = "/bx_block_favourites/favourites";
exports.getPodcastComments = "/bx_block_comments/comments";
exports.getPodcastBands="/bx_block_content_management/audio_podcasts/get_podcast_filter_data";
exports.getPodcastWatchHistory="/bx_block_content_management/audio_podcasts/podcast_watch_history";
exports.createPodcastWatchHistory="/bx_block_content_management/audio_podcasts/create_podcast_watch_history";
exports.getSimilarPodcast="/bx_block_content_management/contents/similar_contents";
exports.getContinueWatchPodcast="/bx_block_content_management/audio_podcasts/podcast_partially_watched";
exports.getpodcastEpisodes="/bx_block_content_management/audio_podcasts/next_podcast_episode";
exports.getPodcastDetails="/bx_block_content_management/audio_podcasts";
exports.getUserCount="bx_block_content_management/contents/get_player_count";
exports.podcastCategoryAPI="/bx_block_content_management/audio_podcasts/podcast_category";
exports.methodTypeApiDelete = "DELETE";
exports.logoutEndPoint="bx_block_account_device/account_devices/delete_account_device"
// exports.getPodcastBands="/bx_block_content_management/audio_podcasts/get_podcast_data";
exports.getPodcastBands = "/bx_block_content_management/audio_podcasts/get_podcast_filter_data";
exports.getPodcastWatchHistory = "/bx_block_content_management/audio_podcasts/podcast_watch_history";
exports.createPodcastWatchHistory ="/bx_block_content_management/audio_podcasts/create_podcast_watch_history";
exports.getSimilarPodcast ="/bx_block_content_management/contents/similar_contents";
exports.getContinueWatchPodcast = "/bx_block_content_management/audio_podcasts/podcast_partially_watched";
exports.getpodcastEpisodes = "/bx_block_content_management/audio_podcasts/next_podcast_episode";
exports.getPodcastDetails = "/bx_block_content_management/audio_podcasts";
exports.getUserCount = "bx_block_content_management/contents/get_player_count";
exports.podcastCategoryAPI = "/bx_block_content_management/audio_podcasts/podcast_category";
exports.welcomeText = "Welcome to Dashboard,";
exports.switchAccountLabel = "Switch Account";
exports.getStreamingUrl = "bx_block_content_management/contents/get_streaming_url";
exports.getMKStreamingUrl = "bx_block_content_management/contents/get_mk_streaming_url?assetId=";
exports.getStreamingUrlWeb = "bx_block_content_management/contents/get_streaming_url?assetId=";
exports.mediaKindBaseUrl = "https://eastus.av.mk.io/cdnn-vod-mediakind";
// Customizable Area End
