//@ts-nocheck
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import {
  ContinueWatchResponse,
  ICWItem,
} from "framework/src/Interfaces/IContinueWatch/IContinueWatchMovies";
import { Message } from "framework/src/Message";
import { messageHelper } from "framework/src/MessageHelper";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import {
  CommonContext,
  CommonContextType,
} from "framework/src/Theme/CommonContextProvider";
import { TFunction } from "i18next";
import React, { Context } from "react";
import { Modalize } from "react-native-modalize";
import {
  BendType,
  CategoryItemType,
  LandingPageResponsetype,
  MovieItemType,
} from "./types";
import i18n from "framework/src/languageSupport/i18n";
// Customizable Area Start
const configJSON = require("../config.js");
import moment from "moment";
import { getHeaders } from "framework/src/requestHeader";
import { getUniqueId } from "react-native-device-info";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { UserContext } from "framework/src/userContext";
// import { CometChat } from "@cometchat-pro/react-native-chat";
import { StorageKeys } from "framework/src/MobileStorageKeys";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  t: TFunction;
}

interface S {
  carouselList: MovieItemType[];
  bends: BendType[];
  bendList: BendType[];
  loading: boolean;
  loadingContinueWatch: boolean;
  errorText: string;
  categories: CategoryItemType[];
  selectCategory: string;
  userID: string;
  refreshing: boolean;
  movieCatId: any;
  TvShowsCatId: any;
  categoryName: string;
  continueWatchList: ICWItem[];
  isSelectedShows: boolean;
  isSelectedMovie: boolean;
  subscriptionStatusForcontinueWatch: boolean;
  buttonName:string[];
}

interface SS { }

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getLandingPageID: string;
  // Customizable Area Start
  getUserProfileListApiCallId: string;
  callCometchatAPICallId:string;
  getButtonNameApiCallId:string
  // Customizable Area End
  getContinueWatchID: string;
  focusListener: any;
  modalizeRef = React.createRef<Modalize>();

  static contextType: Context<CommonContextType | null> | undefined =
    CommonContext;
  static contextType = UserContext;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: true,
      loadingContinueWatch: true,
      refreshing: true,
      carouselList: [],
      errorText: "",
      userID: "",
      bends: [],
      bendList: [],
      categories: [],
      continueWatchList: [],
      selectCategory: i18n.t("landingPage.categories"),
      movieCatId: "",
      TvShowsCatId: "",
      categoryName: "",
      isSelectedShows: false,
      isSelectedMovie: false,
      subscriptionStatusForcontinueWatch: false,
      buttonName:[]
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const userID = await AsyncStorage.getItem("ProfileSelectedID");
    if (userID) {
      this.setState({ userID });
      this.getLandingPageData(userID);
    }
    this.getButtonName(); 
    this.getContinueWatch();
        let data = (await getStorageData("landingPageScreen")) || "{}";
    let continueData = (await getStorageData("continueWatchList")) || "{}";
    this.getMoviesSuccessCallBack(JSON.parse(data));
    this.getContinueWatchSuccess(JSON.parse(continueData));
    if (this.context.user == null) {
      this.getUserProfileList();
    }else{
      this.callCometchatAPI()
    }
    // Customizable Area End
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      // The screen is focused
      // Call any action
      this.getContinueWatch();
    });

  }
  async componentWillUnmount() {
    // Remove the event listener
    this.focusListener.remove();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        // Customizable Area Start
        const apiHandlers = {
          [this.getUserProfileListApiCallId]: this.getUserProfileResponse,
          [this.callCometchatAPICallId]: this.getCometchatResponse,
          [this.getLandingPageID] : this.getMoviesSuccessCallBack,
          [this.getContinueWatchID] : this.getContinueWatchSuccess,
          [this.getButtonNameApiCallId] : this.getButtonNameResponse,
        };
        
        const handler = apiHandlers[apiRequestCallId];
        if (handler) {
          handler.call(this, responseJson);
        }
        // Customizable Area End
      } else if (responseJson.errors) {
        if (apiRequestCallId === this.getLandingPageID) {
          this.getMoviesFailureCallBack(responseJson);
        }
        // Customizable Area Start
        if (responseJson?.error == "Not Authorized, Token Expired") {
          this.getRefreshToken()
        }
        // Customizable Area End
      }
    }
  }

  onRefresh = () => {
    this.onSelectCategory(this.state.categoryName);
  };
  getLandingPageData = async (userId?: string) => {
    const url = `${configJSON.getLandingPageDataURL}?profile_id=${this.state.userID || userId
      }`;
    messageHelper(url, "GET", {}, (id: string) => {
      this.getLandingPageID = id;
    });
  };

  onSelectCategory = (categoryName: string) => {
          this.setState({
        refreshing: true,
        loading: true,
        loadingContinueWatch: true,
        categoryName: categoryName,
      });
    
    const url = `${configJSON.bandFilterAPIEndPoints}?profile_id=${this.state?.userID}&content_type=${categoryName}`;
    messageHelper(url, "GET", {}, (id: string) => {
      this.getLandingPageID = id;
    });
    this.getContinueWatch();
    if (categoryName == "") {
      this.getLandingPageData();
    }
  };

  getContinueWatch = async () => {
    const userId = await AsyncStorage.getItem("ProfileSelectedID");
    this.setState({ loadingContinueWatch: true });

    if (this.state.categoryName == "") {
      const url = `${configJSON.getContinueWatchList}?profile_id=${userId}`;
      messageHelper(url, "GET", {}, (id: string) => {
        this.getContinueWatchID = id;
      });
    } else {
      const url = `${configJSON.getContinueWatchList}?content_type=${this.state.categoryName}&profile_id=${userId}`;
      messageHelper(url, "GET", {}, (id: string) => {
        this.getContinueWatchID = id;
      });
    }
  };

  getMoviesSuccessCallBack = async (responseJson: LandingPageResponsetype) => {
    if (responseJson.success) {
      setStorageData("landingPageScreen", JSON.stringify(responseJson));
      const carouselList = responseJson.thumbnail_contents.data;
      carouselList.sort(
        (a: MovieItemType, b: MovieItemType) =>
          a.attributes.id - b.attributes.id
      );
      carouselList.length = carouselList.length > 5 ? 5 : carouselList.length;
      const bendList = responseJson.bend_contents;
      const AllCategories: CategoryItemType[] = [
        {
          id: "",
          type: "all",
          attributes: {
            id: 0,
            name: i18n.t("landingPage.all"),
            image: "",
          },
        },
        /* ...responseJson.categories.data, 
        will required to revert when BE added code 
        */
      ];
      this.setState({
        carouselList,
        bends: bendList.splice(0),
        loading: false,
        categories: AllCategories,
        refreshing: false,
        bendList,
      });
    }
    /*
    Code commented but may required in a future feature
    responseJson.categories.data.forEach((item: any) => {
      if (item.attributes.name === "Movies") {
        this.setState({ movieCatId: item.attributes.id });
      }
      if (item.attributes.name === "Shows") {
        this.setState({ TvShowsCatId: item.attributes.id });
      }
    });
    */
  };
  getContinueWatchSuccess = (responseJson: ContinueWatchResponse) => {
    this.setState({ continueWatchList: responseJson.data });
    setStorageData("continueWatchList", JSON.stringify(responseJson));
  };
  onloadMoreBands = () => {
    if (this.state.bendList.length > 0) {
      const bendList = [...this.state.bendList];
      const newBends = bendList.splice(0);
      this.setState({ bends: [...this.state.bends, ...newBends], bendList });
    }
  };
  getMoviesFailureCallBack = async () => {
    this.setState({
      refreshing: false,
      loading: false,
    });
  };
  // Customizable Area Start
  getRefreshToken = async () => {
    const header = await getHeaders();
    const device_unique_id = await getUniqueId();
    let formData = new FormData();
    formData.append("device_unique_id", device_unique_id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/refresh_token?device_unique_id=${device_unique_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ ...JSON.parse(header), token: (await getStorageData("RefreshToken")) || "" }),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getUserProfileList = async () => {
    const header = await getHeaders();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/user_profiles"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  navigateToPodcast=()=>{
    this.props.navigation.navigate('Dashboard',{screen:"PodCast"})
  }
  callCometchatAPI = async () => {
    let selectedID = this.context.user.id
    await AsyncStorage.setItem(StorageKeys.ProfileSelectedID, selectedID);

    const header = await getHeaders();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.callCometchatAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/" + selectedID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getCometchatResponse = async(responseJson:any) =>{
    if (responseJson?.meta?.chat_service?.data) {
      let authToken = responseJson.meta.chat_service.data.authToken;
      CometChat.login(authToken);
    }
  }
  getUserProfileResponse =(responseJson:any)=>{
  if (this.context.user == null) {
    this.context.setUser(responseJson.data[0]);
    this.getLandingPageData(responseJson.data[0].id);
  }
}

getButtonName = async () => {
  const header = await getHeaders();
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getButtonNameApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_categories/categories"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
getButtonNameResponse =(responseJson:any)=>{
  const updatedCategories = [...responseJson.data];
  updatedCategories.forEach(category => {
    if (category.attributes.value === "Series") {
      category.attributes.value = "WebSeries";
    }
  });
  this.setState({ buttonName: updatedCategories });
}
  // Customizable Area End
}
