import React from "react";
import VideoShakaPlayerController from "./VideoShakaPlayerController.web";
// Customizable Area Start
import "./ShakaPlayer.css";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { Box, Button, Grid } from "@material-ui/core";

// Customizable Area End

class VideoShakaPlayer extends VideoShakaPlayerController {
  // Customizable Area Start 
  render() {
    return (
      <div className="video-container" ref={this.videoContainer} dir={"ltr"}>
        {this.props.audioOnly &&
          <div className={'image-container shaka-video-container'}>
            <img src={this.props.contentDetails?.image} width="100%" />
          </div>
        }
        <video
          className="shaka-video"
          ref={this.videoComponent}
          poster={!this.props.audioOnly && this.props.contentDetails?.image}
          autoPlay
        />
        {this.state.showTitle && <Box
          id="titleDiv"
          className="title-box"
        >
          <div className="title-div" data-test-id="title-div" onClick={() => this.onClickBackButton()}>
           <NavigateBeforeIcon style={{ height: '45px', width: '45px'}} />
            <span className="single_line">
              {this.state.movieTitle}
            </span>
          </div>
        </Box>}
        {this.state.isShowNext &&
          this.state.preview &&
          this.state.cancelNext && (<div className="nextPreview">
            <Grid container>
              <Grid item xs={12} style={{ position: 'relative' }}
                data-test-id="click_next"
                onClick={(event: any) => {
                  this.onClickPlay(event)
                }}>
                <img
                  src={this.state.preview?.image_url}
                  alt="next episode"
                  className="previewImg"
                />
                <div className="episode-title single_line">
                  {this.state.preview?.title}
                </div>
                <PlayCircleFilledIcon className="play-icon" />
                <div className="up-next">{this.props.t("videos.upNext")}</div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  className="cancel-btn"
                  data-test-id="cancel_btn"
                  variant="contained"
                  onClick={this.updateCancelNext}
                >
                  {this.props.t("videos.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>)}
      </div>
    );
  }
  // Customizable Area End
}

export default VideoShakaPlayer;
