import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
// Customizable Area End

import NotificationsWebController, {
  Props
} from "./NotificationsWebController";
import moment from "moment";

export class NotificationsWeb extends NotificationsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes , isSmallList}: any = this.props;
    const { t }: any = this.props;
    return (
      // Customizable Area Start
      <Container className={isSmallList ? classes.smallScreenCont : classes.largeScreenCont }>
        <Box className={classes.boxStyle}>
            <Typography
              className={isSmallList ? classes.fontStyle : classes.notifyFontStyle}>
              {t("notification.notification")}
            </Typography>
            <Button
            data-test-id="seeAllNot"            
            className={isSmallList ? classes.showallNotif : classes.markAllStyle}
             onClick={this.seeAllNotification}
            >
              {isSmallList && t("notification.viewAll")}
            </Button>
          </Box>
          <Divider className={isSmallList ? classes.blackDivider : classes.dividerLine}  />
          {this.state.data?.length !== 0 ? 
              this.state.data?.map((notfifcations: any, index:number) => {
                return (
                  <List key={notfifcations.id} id="notificationlist" className={!isSmallList && classes.notifiList}>
                    <ListItem
                      style={{
                        cursor: 'pointer',
                        textAlign: "start"
                      }}
                      data-test-id = "onclicknotification"
                      onClick={() => this.markAsReadNotification(notfifcations?.id)}
                    >
                      <ListItemText color="primary">
                        <Box display="flex">
                          {notfifcations?.attributes?.image_url && 
                          <img 
                          src={notfifcations?.attributes?.image_url} 
                          alt="img" className={classes.notificationImage}/>}
                          <Box style={{ marginLeft:'20px' }}>
                          <Typography className={classes.titleStyle}>{notfifcations?.attributes?.title}</Typography>
                          <Typography className={classes.remarkStyle}>{notfifcations?.attributes?.remarks}</Typography>
                          <Typography className={classes.notifyStyle}>
                            {notfifcations?.attributes?.notify_type}
                          </Typography>
                          <Typography className={classes.notificationDateStyle}>
                            {moment(notfifcations?.attributes?.created_at).format('DD-MM-YYYY')}
                          </Typography>
                          </Box>
                        </Box>
                      </ListItemText>
                    </ListItem>
                    {!this.isLastNotification(index) && <Divider variant="middle" className={classes.dividerLine}/>}
                  </List>
                )
              }) : <Typography style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>{t("notification.noNotif")}</Typography>
            }
            
      </Container>
      // Customizable Area End
    );
  }
}

export const useStyles = (theme: any) =>
({
 blackDivider:{
    background: "#141426",
    height: '2px',
    marginBottom: 10
  },
dividerLine: {
  background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
                        height: '2px',
                        marginTop: 10
},
  fontStyle: {
    fontWeight: 600,
    fontSize: '18px',
    fontFamily: "Roboto",
    color: "#F6F6F6"
  },
  notifyFontStyle: {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: "Roboto",
    color: "#FFFFFF",
    padding: theme.direction === "rtl" ? '0 15px 0 0 ' : '0 0 0 15px'
  },

  boxStyle: {
    display: 'flex',
    justifyContent: 'space-between' as const,
    alignItems: 'center' as const,
    padding: "20px 23px",
  },
  showallNotif: {
    fontSize: '14px',
    color: "#EA435D",
    fontWeight: 700,
    fontFamily: "Roboto",
    cursor: 'pointer' as const,
    
  },
  markAllStyle:{
    fontSize: '12px',
    color: "#248CA1",
    fontWeight: 800,
    fontFamily: "Roboto",
    cursor: 'pointer' as const,
  },
  smallScreenCont:{
    width: '100%',
    background: "#07070E",
    borderRadius: 8,
    padding:0
  },
  largeScreenCont:{
    width: '90%',
    background: "#222222",
    borderRadius: 8,
    [theme.breakpoints.only("xs")]: {
      width: "95%"
    },
    padding:0
  },
  notifiList:{
    padding: "5px 25px 0"
  },
  titleStyle:{
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: "Roboto",
    color: "#248CA1"
  },
  remarkStyle:{
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: "Roboto",
    color: "#F6F6F6",
    marginTop: 8
  },
  notifyStyle:{
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: "Roboto",
    color: "#DCDCDC",
    marginTop: 14
  },

  notificationDateStyle:{
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: "Roboto",
    color: "#F6F6F6",
    opacity: 0.8,
    marginTop: 6
  },
  readAllIcon:{
    width: 24,
    height: 24,
    margin: "0px 5px"
  },

  notificationImage:{
    width: 94,
    minHeight: 110,
    height: "auto",
    maxHeight: "100%",
    borderRadius: 8 ,
    border: "1px solid #F6F6F6",
    objectFit: "cover" as const,
    margin : theme.direction === "ltr" ? '0 10px 0 0 ' : '0 0 0 10px'

  }
})
export default withStyles(useStyles)(withTranslation()(NotificationsWeb));
// Customizable Area End
