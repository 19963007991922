/* eslint-disable no-undef */
import React from "react";
// Customizable Area Start
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End
import { withTranslation } from "react-i18next";

export const themeCreatStyle = (theme: any) => ({
  member_main_container: {
    padding: "30px 30px 10px",
    [theme.breakpoints.only("sm")]: {
      padding: "20px 25px 7px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "15px 20px 5px",
    }
  },
  editContainer: {
    padding: "0px 30px 10px",
  },
  underLineCSS: {
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
    height: "2px",
    margin: '10px 0 25px'

  },
  font_Size_24: {
    fontWeight: 700,
    fontFamily: "Roboto",
    fontSize: 24,
    color: "#F6F6F6",
    [theme.breakpoints.only("xs")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 22,
    }
  },
  member_outer_image: {
    width: '100%',
    alignItems: "center",
    display: 'flex',
    flexDirection: "column" as const,
    cursor:"pointer"
  },
  profileDiv: {
    maxWidth: 240,
    width: '100%',
    maxHeight: 240,
    display: 'flex',
    borderRadius: "50%",
    padding: '1px',
    justifyContent: 'center',
    minHeight: "95px",
    background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)"
  },
  goToMember: {
    fontSize: 15,
    fontFamily: "Roboto",
    textAlign: "end" as const,
    textDecoration: 'underline',
    fontWeight: 500,
    cursor: "pointer",
    color: "#EA435D"
  },
  typo_background: {
    fontSize: 16,
    fontFamily: "Roboto",
    wordBreak: 'break-all' as const,
    padding: '10px 0',
    textAlign: "center" as const,
    fontWeight: 600,
  },
});

// Customizable Area End

import MemberProfileController, { Props, MemberProfileInter } from "./MemberProfileController.web";
import Spinner from "./shared/Spinner.web";
import EmailVerificationWeb from "../../email-account-login/src/EmailVerificationWeb.web";
import { dummy_icon } from "./assets";

export class MemberProfile extends MemberProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    // Customizable Area Start
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid
          item
          xs={12}
          className={classes.member_main_container}
        >
          <Typography
            className={classes.font_Size_24}
          >
            {t("settingDialogWeb.members")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.underLineCSS}
        />
        <Grid
          item
          xs={12}
          className={this.state.isEdit ? classes.editContainer : classes.member_main_container}
        >
          {this.state.isEdit ? <>
            <Grid item xs={12}>
              <Typography data-test-id="gotomembber" className={classes.goToMember} onClick={() => this.changeEditToMember()}>{t("settingDialogWeb.goToMember")} </Typography>
            </Grid>
            <EmailVerificationWeb {...this.props} profileId={this.state.memberId} profileType={this.state.memberType} onChangeTab={this.changeEdit} /></>
            : <Grid container spacing={3}>
              {this.state.memberProfileDetails.map((_item: MemberProfileInter, index: number) => {
                return (
                  <Grid item xs={6} sm={4} className={classes.member_outer_image} key={_item?.id}>
                    <Grid item xs={12}
                      className={classes.profileDiv}
                      data-test-id={`selectUser${_item.id}`}
                      onClick={() => {
                        this.onUserSelect(_item, index)
                      }}
                    >
                      <img
                        style={{ width: '100%', borderRadius: "50%", aspectRatio: "1" }}
                        src={_item?.attributes?.photo == null ? dummy_icon : _item?.attributes?.photo}
                      />
                    </Grid>
                    <Typography
                      className={`${classes.typo_background} two_lines`}
                      color="primary"
                    >{_item?.attributes?.name}</Typography>
                  </Grid>


                )
              })}
            </Grid>}
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(
  withTranslation()(MemberProfile)
);
// Customizable Area End
