import React from "react";
// Customizable Area Start
import {

  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import SessionExp from "../../dashboard/src/SessionExp.web";
import {
  addUserProfile,
  add_icon,
  dummy_icon,
  edit_icon_light,
  edit_web_dark,
  premiumIcon,
  premiumImage,
  rightTick,
  
} from "./assets";
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
 root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    backgroundImage_div: {
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
  
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
  
      [theme.breakpoints.only("xs")]: {
        padding: "38px 25px 29px",
      },
      padding: "58px 50px 39px",
      
    },
    rightTick: {
      width: '30px',
      position: 'absolute' as const,
      height: '30px',
      bottom: 10,
      right: 10
    },
    box_css: {
      width: '100%',
      height: "calc(100% - 44px)",
      background: "#222222",
      borderRadius: '50%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "95px",
      minWidth: "95px"

    },
  description_text: {
     fontFamily: "Araboto",
      fontWeight: 500,
      fontStyle: "normal",
      letterSpacing: 0,
      fontSize: '14px'
    },
    description_text1: {
      fontFamily: "Araboto",
       fontWeight: 400,
       fontStyle: "normal",
       letterSpacing: 0,
       fontSize: '14px'
     },
    font_size_20: {
      fontSize: 20,
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      }      
    },
  details_description_text: {
      color: theme.palette.secondary.main,
      fontFamily: "Araboto",
      fontWeight: 400,
      fontStyle: "normal",
      letterSpacing: 0,
      fontSize: '18px',
      lineHeight: '22px',
      margin: '7px 0'
    },
     font_size_16: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 15.5,
      },
      fontSize: 16
    },
    
    may_be_button_css: {
      boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
      backgroundColor: `rgba(234,67,93, 1)`,
      color: theme.palette.primary.main,
      borderRadius: 10,
      fontWeight: 700,
      opacity: 0.9,
      marginTop: 30,
  
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
        marginTop: 25,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
        maxWidth: 280,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
        maxWidth: 230,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
        maxWidth: 280,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        maxWidth: 280,
      },
    },
    subscription_details_outer_div: {
      background:
        "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
      padding: 5,
      borderRadius: 8,
      
    },
  
    subscription_details_div: {
      background:
        theme.palette.type == "dark" ? "linear-gradient(90deg, rgba(20, 21, 35, 1) 0%, rgba(26, 27, 34, 1) 100%)" : "#D3D3D3",
      borderRadius: 8,
      padding: 15,
      flexDirection: "column" as const
    },
    image_outer_div: {
      width: '100%',
      cursor: 'pointer',
    },
   typo_background: {
      fontSize: 16,
      fontFamily: "Araboto",
      wordBreak: 'break-all' as const, 
      padding: '10px 0',
      fontWeight: 500,
      width:"100%",
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      textAlign:"center" as const
    },
    price_div: {
      background: "linear-gradient(91.24deg, rgba(234, 67, 93, 0.5) 1.44%, rgba(104, 105, 172, 0.5) 52.28%, rgba(36, 140, 161, 0.5) 98.95%)",
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      width: '100%',
      padding: '10px',
      maxWidth: '120px',
      marginTop: '7px'
    },
   edit_icon: {
      width: 30,
      height: 30,
    },
    chooseProfileContainer: {
      display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,
      margin: '20px 0'
    },
    profileDiv: {
      display: 'flex', 
      position: 'relative' as const, 
      justifyContent: 'center', 
      alignItems: "center" as const, 
      borderRadius: "50%",
      padding: '2px',
      minHeight: "95px"
    },
    selectedProfileDiv: {
      background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)"
    },
    AppLogo: {
      maxWidth: "120px",
      width: "auto",
      aspectRation : "1/1",
       marginBottom: 20,
      
    },
    planContainer: {
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      padding: "40px 45px",
      borderRadius: "8px"
    },
    outerContainer: {
      margin: "auto",
      maxWidth: '600px'
    },
    planIcons:{
      width: 42,
      height: 42,      
    },
    addUserProfileIcon: {
      width: '100%',
      borderRadius: "50%",
      aspectRatio : 1
    },
    kidsTypo: {
      fontSize: 14,
      fontFamily: "Araboto",
      color: "#FFFFFF",
      padding: '3px 12px',
      fontWeight: 700,
      background: 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)',
      borderRadius: '10px',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center' as const
    }

  })
// Customizable Area End

import UserProfileController, {
  Props,
} from "./UserProfileController.web";
import AddEditUserWeb from "./AddEditUser.web";
import Spinner from "./shared/Spinner.web";
import { VODLogo } from "../../forgot-password/src/assets";
import SettingDialog  from "./SettingDialog.web";

export class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t }: any = this.props;

    // Customizable Area Start
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        {this.state.logout && <SessionExp deleteModal={this.state.logout} onCloseModal={()=>this.logOut()} />}
        <Grid
          item
          xs={12}
          className={classes.backgroundImage_div}
        >
          <Grid container className={classes.outerContainer}>
          <Grid container justifyContent="center">
            <img className={classes.AppLogo} src={VODLogo} />
            </Grid>
          <Grid container className={classes.planContainer}>
            <Grid item xs={12}>
              <Typography color="primary" className={classes.font_size_20} style={{ fontFamily: 'Araboto',  marginBottom: '10px', textAlign: "center" }}>
                {t("choose_profile.CPSP")}
              </Typography>
            </Grid>
            {
              this.state.selectedPlanDetail?.data?.attributes && <Grid item xs={12}>
              <div
                className={classes.subscription_details_outer_div}
              >
                <Grid
                  container
                  className={classes.subscription_details_div}
                  spacing={1}
                >
                  
                  <Grid item xs={12}>
                    <img src={this.state.selectedPlanDetail?.data?.attributes?.icon ?this.state.selectedPlanDetail?.data?.attributes?.icon : premiumIcon } alt="preminumLogo" className={classes.planIcons} />
                  </Grid>
                  <Typography
                      className={classes.details_description_text}>
                      {this.state.selectedPlanDetail?.data?.attributes?.name}
                    </Typography>
                    <Typography
                    color="secondary"
                      className={classes.description_text1}>
                      {this.state.selectedPlanDetail?.data?.attributes?.user_limit_desc}
                      </Typography>
                  <div className={classes.price_div}>
                      <Typography
                      color="secondary"
                        className={classes.description_text}>
                          $ {this.state.selectedPlanDetail?.data?.attributes?.price}
                        / {this.state.selectedPlanDetail?.data?.attributes?.duration}
                      </Typography>
                    </div>
                
                </Grid>
              </div>
            </Grid>}
            <Grid item xs={12}>
              <Grid container >
                <Grid item xs={12} className={classes.chooseProfileContainer}>
                <Typography  color="primary" className={classes.font_size_16} style={{ fontFamily: 'Araboto',  marginBottom: '10px' }}>
                {t("choose_profile.ChooseP")}
              </Typography>
                  <img src={localStorage.getItem("darkMode") == "false" ? edit_icon_light : edit_web_dark} alt="edit_icon" style={{
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                    className={classes.edit_icon} 
                    data-test-id="openDialog"
                    onClick={(event: any) => this.onEditClick(event)} />
                </Grid>

                <Grid item xs={12} className={classes.description_Box}>

                  <Grid container spacing={3}>                    
                    {this.state.userProfileDetails.map((_item: any) => {
                      return (
                        <Grid item xs={6} sm={4} className={classes.image_outer_div} key={_item?.id}>
                          <Grid item xs={12}
                          className={`${classes.profileDiv} ${this.state.selectedUserID == parseInt(_item.id) && classes.selectedProfileDiv} `}
                          data-test-id={`selectUser${_item.id}`}
                          onClick={() => {
                            this.onSelectProfile(_item)
                          }}>
                            {_item?.attributes?.kids_profile && <Grid item xs={12} style={{ width: '100%', position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                              <Typography
                                className={classes.kidsTypo}                                
                              >{t("choose_profile.KP")}</Typography>


                            </Grid>}
                            {this.state.selectedUserID == parseInt(_item.id) && <img
                              className={classes.rightTick}
                              src={rightTick}
                             />}
                            <img
                              className={classes.addUserProfileIcon}
                              src={_item?.attributes?.photo == null ? dummy_icon : _item?.attributes?.photo}
                             />
                          </Grid>
                          <Typography
                                className={`${classes.typo_background} two_lines`}
                                color="primary"
                              >{_item?.attributes?.name}</Typography>
                        </Grid>


                      )
                    })}
                    {this.checkForAddUser() && <Grid item xs={6} sm={4} className={classes.image_outer_div}>
                      <Grid item xs={12} onClick={() => this.handleAddMoreUser()} className={classes.profileDiv}
                        data-test-id="addUser"
                        >
                       <img
                               className={classes.addUserProfileIcon}
                              src={addUserProfile}
                            />                      
                      </Grid>
                        <Typography
                            color="primary"
                            className={`${classes.typo_background} two_lines`}
                          >{t("choose_profile.ANP")}</Typography>

                    </Grid>}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
            {this.state.selectedUserID !== "" && <Grid xs={12} item style={{ display: "flex", justifyContent: "center" }}>
              <Button fullWidth className={classes.may_be_button_css}
              variant="contained"
              data-test-id='doneButton'
                onClick={(event: any) => this.applySelectedPreference(event)
                }
              >
                {t("choose_profile.done")}
              </Button>
            </Grid>}
          </Grid>
          </Grid>
        </Grid>

        {Boolean(this.state.settingAnchor) && <SettingDialog data-test-id="close" {...this.props} settingAnchor={this.state.settingAnchor} setSettingAnchor={()=>this.setSettingAnchor()} />}
        
        <AddEditUserWeb
          addEditUserClick={(event: any) => this.addUser(event)}
          userPhoto={this.state.userPhoto}
          handleAddEditUserDialogClose={() => this.setState({ userModal: false })}
          isEditUser={this.state.userEdit}
          addEditUserDialog={this.state.userModal}
          userName={this.state.userName}
          userNameError={this.state.userNameError}
          userNameErrorMsg={this.state.userNameErrorMes}
          handleImageClick={(event: any) => {

            this.setState({ userPhoto: event.target.files[0] })
          }}
          onChangeUserName={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeUserName(event)}
          onBlurUserName={() => this.handleBlurUserName()}
          onChecked={(event: any) => {
            this.setState({ kidsPorfile: event.target.checked })
          }}
          kidsProfile={this.state.kidsPorfile}

        />
      </Grid >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(UserProfile));
// Customizable Area End
