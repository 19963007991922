import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";


// Customizable Area Start
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
import CreateForwardButton from "./ForwardButton";
import CreateRewindButton from "./RewindButton";
import CaptionsButton from "./CaptionsButton";
import SettingsButton from "./SettingsButton";
import CONTENT_TYPES from '../../../components/src/Enums';
import moment from "moment";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  createWatchHistory: any;
  assetID: any;
  streamingURL: any;
  contentDetails: any;
  onClosePlayer: any;
  title?: string;
  onClickNext?: any;
  getEpisodesByRelease?: any;
  t: any;
  audioOnly?: boolean;
  onClickNextPart?: any;
  currentUserPlan?: any;
  isMediaKind?: boolean;
  subtitleDetails: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  streamingUrl: string;
  showTitle: boolean;
  mouseMove: boolean;
  movieTitle: string;
  localStreamingUrl: any;
  nextStreaming: any;
  preview: any;
  nextRelease: any;
  assetID: any;
  isShowNext: boolean;
  currentSeasonId: any;
  cancelNext: boolean;
  isContinueApiCalled: boolean;
  isNextApiCalled: boolean;
  nextPart: any;
  watchHistoryApiCall: boolean;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoShakaPlayerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  videoComponent: any;
  videoContainer: any;
  player: any;
  ui: any;
  shakaUi: any;
  video: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      streamingUrl: "",
      showTitle: true,
      mouseMove: false,
      movieTitle: "",
      localStreamingUrl: '',
      nextStreaming: undefined,
      preview: undefined,
      nextRelease: undefined,
      assetID: '',
      isShowNext: false,
      currentSeasonId: '',
      cancelNext: true,
      isContinueApiCalled: false,
      isNextApiCalled: false,
      nextPart: undefined,
      watchHistoryApiCall: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoComponent = React.createRef();
    this.videoContainer = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.initPlayer();
    this.setTitle();
    this.checkForNextPart();
    this.applyCustomStyles();
  }

  async applyCustomStyles() {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = `
      .shaka-text-container {
        // position: fixed !important;
      }
    `;
    document.head.appendChild(styleSheet);
  }

  async componentWillUnmount() {
    this.player.destroy()
    if (this.video) {
      this.video.removeEventListener("timeupdate");
      this.video.removeEventListener("loadedmetadata");
    }
  }

  setTitle = () => {
    let movieTitle = this.props.title ? this.props.title : this.props.contentDetails.title;
    this.setState({ movieTitle })
  }

  initPlayer = () => {
    this.video = this.videoComponent.current;
    const videoContainer = this.videoContainer.current;
    this.player = new shaka.Player(this.video);
    this.player.selectTextTrack(true);
    const tracks = this.player.getTextTracks();
    
    if (tracks.length > 0) {
      this.player.selectTextTrack(tracks[0]);
    }
    this.player.setVideoContainer(true);
    
    this.shakaUi = shaka.ui;
    this.shakaUi.Controls.registerElement("rewind_10", new CreateRewindButton.Factory());
    this.shakaUi.Controls.registerElement("settings", new SettingsButton.Factory());
    this.shakaUi.Controls.registerElement("captions", new CaptionsButton.Factory());
    this.shakaUi.Controls.registerElement("forward_10", new CreateForwardButton.Factory());

    //Setting UI configuration JSON object
    const config = {
      controlPanelElements: [
        "play_pause",
        "rewind_10",
        "forward_10",
        "mute",
        "volume",
        "spacer",
        "captions",
        "settings",
        "fullscreen",
      ],
      overflowMenuButtons: ["quality", "playback_rate"],
      addBigPlayButton: true,
      addSeekBar: true,
    };

    //Setting up shaka player UI
    this.ui = new shaka.ui.Overlay(this.player, videoContainer, this.video);
    this.player.configure({
      drm: {
        servers: {
          "com.widevine.alpha": this.props.isMediaKind ? 
          configJSON.mediaKindToken :
          "https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=F283BA"
        },
      },
    });
    this.ui.configure(config); //configure UI

    this.props.isMediaKind && this.player
      .getNetworkingEngine()
      .registerRequestFilter(function (type: any, request: any) {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers["Authorization"] = configJSON.mediaKindHeader
      }
    });  

    this.player.load(this.props.streamingURL).then(() => {
      this.addSubtitles();
    });
    // this.addSubtitles();
    this.addContainerEvents();
    this.createSeekBar();
    this.addVideoEvents();
  };

  addSubtitles = () => {
    const  subtitleDetails  = this.props.subtitleDetails; 
    subtitleDetails.forEach((subtitle: any) => {
      this.player.addTextTrackAsync(
        subtitle.url,
        // subtitle.display_name,
        subtitle.language_code,
        "text/vtt"
      );
    });
  };
  

  addContainerEvents = () => {
    const titleTimer = this.getTitleTimer()
    if (!this.ui.getControls().getVideo().paused) {
      this.setState({ showTitle: false });
    }

    this.ui.getControls().getVideoContainer().addEventListener('mouseleave', () => {
      if (!this.ui.getControls().getVideo().paused) {
        this.setState({ showTitle: false });
      }
    });
    this.ui.getControls().getVideoContainer().addEventListener('mousemove', () => {
      this.setState({ showTitle: true, mouseMove: true });
      titleTimer.tickAfter(3)
    });
    this.ui.getControls().getVideoContainer().addEventListener('click', () => {
      if (this.ui.getControls().getVideo().paused) {
        this.setState({ showTitle: true });
      }
    });
  }

  getTitleTimer = () => {
    return new shaka.util.Timer(() => {
      this.setState({ mouseMove: false });

      if (!this.ui.getControls().getVideo().paused) {
        this.setState({ showTitle: false });
      }
    });
  }

  createSeekBar = () => {
    const targetElement = document.getElementsByClassName(
      "shaka-seek-bar-container"
    )[0];
    if (targetElement) {
      const newParentElement = document.createElement("div");
      newParentElement.className =
        "seekBarAndDuration";
      targetElement?.parentNode?.insertBefore(newParentElement, targetElement);
      newParentElement?.appendChild(targetElement);
    }

    const seekBarAndTime =
      document.getElementsByClassName("seekBarAndDuration")[0];
    if (seekBarAndTime) {
      const overlayTime = document.createElement("div");
      overlayTime.textContent = `00:00 / 00:00`;
      overlayTime.className = "timeAndDuration";
      seekBarAndTime.appendChild(overlayTime);
    }

  }
  addVideoEvents = () => {
    if (this.video) {
      this.video.addEventListener("timeupdate", this.timeChange);

      this.video.addEventListener("loadedmetadata", () => {
        this.setSeekBar();
        this.video.currentTime = this.playAfterWatched();
        this.filterResolutions();
      });
    }
    this.checkNextStreaming(this.props);
  };

  filterResolutions = () => {
    const tracks = Array.from(document.querySelectorAll('.shaka-resolutions .explicit-resolution'));
    tracks.forEach((v: any) => {
      if (parseInt(v.innerText) > parseInt(this.props.currentUserPlan?.resolution))
        v.remove()
    });
  }

  setSeekBar = () => {
    const timeAndDurationHTML =
      document.getElementsByClassName("timeAndDuration")[0];
    let duration = isNaN(this.video.duration) ? "00 : 00" : this.formatTime(this.video.duration);
    if (timeAndDurationHTML) {
      timeAndDurationHTML.textContent = `${this.formatTime(
        this.video.currentTime
      )}/ ${duration}`;
    }
  }

  playAfterWatched = () => {
    const { contentDetails, assetID } = this.props;
    const { watch_history } = contentDetails;
    let currentTime = (watch_history &&
      (watch_history.asset_id === contentDetails.asset_id ||
        watch_history.asset_id === assetID) &&
      !watch_history.is_completed &&
      watch_history.watching_duration !== 0) ?
      parseInt(watch_history.watching_duration) : 0;
    return currentTime
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (this.props.title !== prevProps.title || this.props.contentDetails.title !== prevProps.contentDetails.title) {
      this.setTitle()
    }
    if (this.props.streamingURL !== prevProps.streamingURL) {
      this.setState({
        isContinueApiCalled: false,
        isNextApiCalled: false,
      })
      await this.player.unload();
      await this.player.load(this.props.streamingURL);
      this.checkNextStreaming(this.props);
      this.checkForNextPart();
    }
  }

  formatTime = (timeInSeconds: any) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = seconds.toString().padStart(2, "0");
    let timeFormate: string;
    if (hours > 0) {
      timeFormate = `${hoursString}:${minutesString}:${secondsString}`;
    } else {
      timeFormate = `${minutesString}:${secondsString}`;
    }
    return timeFormate;
  };

  timeChange = () => {
    const videoElement = this.videoComponent.current;
    let currentTime = videoElement.currentTime;
    let totalTime = videoElement.duration;
    const seekBarAndTime =
      document.getElementsByClassName("timeAndDuration")[0];
    if (seekBarAndTime) {
      seekBarAndTime.textContent = `${this.formatTime(
        currentTime
      )}/ ${this.formatTime(totalTime)}`;
    }
    this.createContinueWatching(currentTime, currentTime > 0, totalTime);
  };

  createContinueWatching = (
    currenTime: any,
    isStarted: boolean,
    totalDuration: any
  ) => {
    const { assetID, createWatchHistory } = this.props;
    const { nextStreaming, nextRelease, nextPart } = this.state;
    let currenTimeTrunc = Math.trunc(currenTime);
    let totalDurationTrunc = Math.trunc(totalDuration);
    let videoCompleletdPecent = (currenTimeTrunc / totalDurationTrunc) * 100;
    let isCompleted =
      currenTimeTrunc >= totalDurationTrunc - 60 && videoCompleletdPecent >= 95;
    let isPartAndEpisode = nextStreaming || nextRelease || nextPart;
    let isWebSeriesCompleted = isCompleted && isPartAndEpisode;
    isWebSeriesCompleted ?
      this.setState({ isShowNext: true }) : this.setState({ isShowNext: false, cancelNext: true });

    if (
      !isNaN(totalDuration) &&
      currenTimeTrunc > 0 &&
      (currenTimeTrunc % 30 === 0 || isCompleted) &&
      !this.state.isContinueApiCalled && !this.state.watchHistoryApiCall
    ) {
      createWatchHistory(
        assetID,
        isStarted,
        isCompleted,
        totalDurationTrunc,
        currenTimeTrunc
      );
      this.setState({ watchHistoryApiCall: true })
      isCompleted && this.setState({ isContinueApiCalled: true })
    }
    this.state.watchHistoryApiCall && currenTimeTrunc % 30 !== 0 && this.setState({ watchHistoryApiCall: false })

    if (isWebSeriesCompleted &&
      currenTimeTrunc >= totalDurationTrunc - 15 &&
      videoCompleletdPecent >= 98 &&
      !this.state.isNextApiCalled
      && this.state.cancelNext) {
      this.checkNextRelease();
      this.setState({ isNextApiCalled: true })
    }
    if (currenTimeTrunc === totalDurationTrunc) {
      (!isPartAndEpisode || (isPartAndEpisode && !this.state.cancelNext)) && this.props.onClosePlayer()
    }
  };

  onClickBackButton = async () => {
    if (document.fullscreenElement === null) {
      this.createContinueWatching(this.video.currentTime, false, this.video.duration);
      this.player.unload();
      window.location.reload();
      this.props.onClosePlayer();
    } else {
      await document.exitFullscreen()
    }
  }

  checkNextStreaming = (propsAsParam: any) => {
    const { streamingURL, assetID, seasonsData, playingSeason, isSubscribed } = propsAsParam;
    this.setState({ localStreamingUrl: streamingURL, assetID, currentSeasonId: playingSeason });
    if (seasonsData && seasonsData?.data?.length > 0 && isSubscribed) {
      const episodeObj = (episode: any) => episode?.attributes.asset_id === assetID;
      const index = (seasonsData?.data || []).findIndex(episodeObj);
      if (index !== -1 && index < (seasonsData?.data?.length - 1)) {
        const nextStreaming = seasonsData?.data[index + 1];
        const preview = { image_url: nextStreaming?.attributes.thumbnail_image, title: nextStreaming?.attributes.title }
        this.setState({ nextStreaming: { ...nextStreaming?.attributes }, preview });
      } else {
        const releases = seasonsData?.releases || [];
        const releaseObj = (release: any) => release.id == playingSeason;
        const releaseIndex = releases.findIndex(releaseObj);
        if (releaseIndex == (releases?.length - 1)) {
          this.setState({ nextRelease: undefined, nextStreaming: undefined });
        } else {
          const nextRelease = releases[releaseIndex + 1];
          const preview = { image_url: this.props?.contentDetails?.thumbnail_image, title: nextRelease?.attributes?.type_name }
          this.setState({ nextRelease: { ...nextRelease }, nextStreaming: undefined, preview });
        }
      }
    }
  }

  checkForNextPart = () => {
    const { contentDetails } = this.props;
    if (contentDetails?.parts?.length) {
      let partsAfterCurrentPlay = contentDetails.parts.filter((_data: any) => {
        return moment(_data?.release_date).isSameOrAfter(contentDetails?.release_date)
      })
      partsAfterCurrentPlay?.length > 1 && partsAfterCurrentPlay.sort((a: any, b: any) => {
        const dateA: any = moment(a.release_date);
        const dateB: any = moment(b.release_date);
        return dateA - dateB;
      });

      if (partsAfterCurrentPlay?.length) {
        const preview = { image_url: partsAfterCurrentPlay[0].thumbnail_image, title: partsAfterCurrentPlay[0].title }
        this.setState({ nextPart: partsAfterCurrentPlay[0], preview });
      } else {
        this.setState({ nextPart: undefined })
      }

    }
  }

  updateCancelNext = (event: any) => {
    event.stopPropagation();
    this.setState({ cancelNext: false });
  }

  onClickPlay = (event: any) => {
    event.stopPropagation()
    this.checkNextRelease()
  }

  checkNextRelease = () => {
    const { contentDetails } = this.props;
    if (contentDetails?.content_type == CONTENT_TYPES.WEBSERIES) {
      this.state?.nextRelease ? this.onClickNextSeason() : this.onClickNextEpisode(this.state?.nextStreaming);
    }
    if (contentDetails?.content_type == CONTENT_TYPES.MOVIES) {
      this.setState({ isShowNext: false })
      this.props.onClickNextPart(this.state.nextPart)
    }
  }

  onClickNextEpisode = (nextEpisode: any) => {
    const { asset_id } = nextEpisode || {};
    const { onClickNext } = this.props;
    this.setState({ assetID: asset_id, isShowNext: false });
    onClickNext(nextEpisode);
  }

  onClickNextSeason = () => {
    const { getEpisodesByRelease, contentDetails } = this.props;
    this.setState({ isShowNext: false, localStreamingUrl: '' });
    getEpisodesByRelease(contentDetails?.id, this.state.nextRelease?.id, true);
  }
  //Customizable Area End
}