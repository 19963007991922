import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";

import {
  addProfileIcon,
  dummy_icon,
  premiumIcon,
  rightTick
} from "./assets";
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  backgroundImage_div: {
    padding: "30px",
    [theme.breakpoints.only("sm")]: {
      padding: "20px 25px",
    },
    [theme.breakpoints.only("xs")]: {
        padding: "15px 20px",
    }
  },
  description_text1: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
             letterSpacing: 0,
             color: "#DCDCDC",
             fontSize: '16px',
             marginBottom: "5px",
  },
  description_text:{
    fontFamily: "Roboto",
            fontWeight: 400,
            fontStyle: "normal",
            letterSpacing: 0,
            color: '#F6F6F6'
  },
  description_Box: {
    [theme.breakpoints.only("xs")]: {
      marginTop: 30,
    },
    [theme.breakpoints.only("md")]: {
      marginTop: 45,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: 40,
    },
    marginTop: 50
  },
  details_description_text: {
    fontFamily: "Roboto",
    color: "#F6F6F6",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: 0,
    margin: '7px 0',
    fontSize: '20px',
    lineHeight: '22px',
  },
  choose_profile_text: {
    fontFamily: "Roboto",
    color: "#F6F6F6",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: 0,
    margin: '20px 0 0' ,
    fontSize: '16px',
    lineHeight: '20px',
  },
  profileDiv: {
    display: 'flex', 
    position: 'relative' as const, 
    justifyContent: 'center', 
    alignItems: "center" as const, 
    borderRadius: "50%",
    padding: '2px',
    minHeight: "95px"
  },
  selectedProfileDiv: {
    background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)"
  }, 
  profileImage: {
    width: '100%',
    borderRadius: '50%',
    aspectRatio: 1,
    cursor:"pointer"
  },  
  may_be_button_css: {
    boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
    color: theme.palette.primary.main,
    borderRadius: 10,
    fontWeight: 700,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      marginTop: 35,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 17,
      marginTop: 42,
      maxWidth: 280,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
      marginTop: 37,
      maxWidth: 230,
    },
    fontSize: 18,
      marginTop: 45,
      maxWidth: 280,    
    "&:disabled": {
      backgroundColor: `rgba(234,67,93, 1)`,
      color: theme.palette.primary.main,
    },
  },
  subscription_details_outer_div: {
    background:
      "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    borderRadius: 8,
    padding: 5,
  },

  subscription_details_div: {
    background:"linear-gradient(90deg, rgba(20, 21, 35, 1) 0%, rgba(26, 27, 34, 1) 100%)",
    padding: 15,
    borderRadius: 8,
    flexDirection: "column" as const     
  },
  image_outer_div: {
    width: '100%',
    maxWidth: 200  
  },
  kidsProfile: {
    fontSize: 14,
    fontFamily: "Araboto",
    padding: '3px 12px',
    color: "#FFFFFF",
    fontWeight: 700,
    background: 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)',
    alignContent: 'center',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center' as const
  },
  planIcons:{
    width: 42,
    height: 42,      
  },
  font_Size_20: {
    fontWeight: 700,
    fontSize: 22,
    fontFamily: "Roboto",
    [theme.breakpoints.only("xs")]: {
        fontSize: 18,
    },
    [theme.breakpoints.only("sm")]: {
        fontSize: 19,
    }
},
typo_background: {
    fontSize: 16,
    fontFamily: "Araboto",
    fontWeight: 500,
    wordBreak: 'break-all' as const,
    padding: '10px 0',
    width: "100%",
    alignItems: 'center',
    display: 'flex',
    alignContent: 'center',
    textAlign: "center" as const
  },
  underLine: {
    height: "1.5px",
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)"
},
  selectedTick: {
    width: '30px',
    height: '30px',
    position: 'absolute' as const,
    bottom: 10,
    right: 10
  },
  priceContainer: {
    background: "linear-gradient(91.24deg, rgba(234, 67, 93, 0.5) 1.44%, rgba(104, 105, 172, 0.5) 52.28%, rgba(36, 140, 161, 0.5) 98.95%)",
    borderRadius: 2,
    display: "flex",
    padding: '10px',
    justifyContent: "center",
    maxWidth: '150px',
    marginTop: '7px'
  },
});
// Customizable Area End

import UserProfileController, {
  Props,
} from "./UserProfileController.web";
import AddEditUserWeb from "./AddEditUser.web";
import Spinner from "./shared/Spinner.web";
import { withTranslation } from 'react-i18next';
export class SwitchProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t }: any = this.props;

    // Customizable Area Start
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid
          item
          xs={12}
          className={classes.backgroundImage_div}
       >
          <Typography
            className={classes.font_Size_20}
            color="primary"
          >
            {t("choose_profile.SP")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.underLine}
        />
        <Grid
          item
          xs={12}
          className={classes.backgroundImage_div}
        >
          <Grid container>            
            <Grid item xs={12}>
              {this.state.selectedPlanDetail.data?.attributes && <div
                className={classes.subscription_details_outer_div}
              >
                <Grid
                  container
                  className={classes.subscription_details_div}
                  spacing={1}
                >
                  <div>
                    <img src={this.state.selectedPlanDetail.data?.attributes?.icon ? this.state.selectedPlanDetail.data?.attributes?.icon : premiumIcon} alt="preminumLogo" className={classes.planIcons} />
                  </div>
                  <Typography
                    className={classes.details_description_text}>
                    {this.state.selectedPlanDetail.data?.attributes?.name}
                  </Typography>
                  <Typography
                    className={classes.description_text1}>
                    {this.state.selectedPlanDetail.data?.attributes?.user_limit_desc}
                  </Typography>
                  <div className={classes.priceContainer}>
                    <Typography
                      color="secondary"
                      className={classes.description_text}>
                      $ {this.state.selectedPlanDetail.data?.attributes?.price}
                      / {this.state.selectedPlanDetail.data?.attributes?.duration}
                    </Typography>
                  </div>

                </Grid>
              </div>}
            </Grid>
            <Grid item xs={12}>
              <Grid container >
                <Grid item xs={12}>
                <Typography
            className={classes.choose_profile_text}
            color="primary"
          >
            {t("choose_profile.ChooseP")}
          </Typography>
                </Grid>

                <Grid item xs={12} className={classes.description_Box}>

                  <Grid container spacing={2}>                  
                    {this.state.userProfileDetails.map((_item: any, i:any) => {
                      return (
                        <Grid item xs={6} sm={4} className={classes.image_outer_div} key={_item?.id}>
                          <Grid item xs={12} 
                          className={`${classes.profileDiv} ${this.state.selectedUserID == parseInt(_item.id) && classes.selectedProfileDiv} `}
                          data-test-id={`selectUser${i}`}
                          onClick={() => {
                            this.setState({ selectedUserID: parseInt(_item.id) })
                          }}>
                            {_item?.attributes?.kids_profile && <Grid item xs={12} style={{ width: '100%', position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                              <Typography
                              className={classes.kidsProfile}    
                              >{t("choose_profile.KP")}</Typography>


                            </Grid>}
                            {this.state.selectedUserID == parseInt(_item.id) && <img
                              className={classes.selectedTick}
                              src={rightTick}
                             />}
                            <img
                              className={classes.profileImage}
                              src={_item?.attributes?.photo == null ? dummy_icon : _item?.attributes?.photo}
                             />
                          </Grid>
                          <Typography
                                className={`${classes.typo_background} two_lines`}
                                color="primary"
                              >{_item?.attributes?.name}</Typography>                          
                        </Grid>
                      )
                    })}
                    {this.checkForAddUser() &&  <Grid item xs={6} sm={4} className={classes.image_outer_div}>
                      <Grid item xs={12} 
                      data-test-id="addUser"
                      onClick={() => this.handleAddMoreUser() } className={classes.profileDiv}>
                      <img                      
                              className={classes.profileImage}
                              src={addProfileIcon}
                              alt="add"                              
                            />                        
                      </Grid>
                        <Typography
                            color="primary"
                            className={`${classes.typo_background} two_lines`}
                          >{t("choose_profile.ANP")}</Typography>

                    </Grid>}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button fullWidth className={classes.may_be_button_css}
              variant="contained"
                 onClick={(event: any) => this.applySelectedPreference(event)
                }
                disabled={this.state.selectedUserID == "" || this.state.selectedUserID.length == 0}

              >
                {t("choose_profile.done")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AddEditUserWeb
          addEditUserClick={(event: any) => this.addUser(event)}
          userPhoto={this.state.userPhoto}
          handleAddEditUserDialogClose={() => this.setState({ userModal: false })}
          isEditUser={this.state.userEdit}
          addEditUserDialog={this.state.userModal}
          userName={this.state.userName}
          userNameError={this.state.userNameError}
          userNameErrorMsg={this.state.userNameErrorMes}
          handleImageClick={(event: any) => {

            this.setState({ userPhoto: event.target.files[0] })
          }}
          onChangeUserName={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeUserName(event)}
          onBlurUserName={() => this.handleBlurUserName()}
          onChecked={(event: any) => {
            this.setState({ kidsPorfile: event.target.checked })
          }}
          kidsProfile={this.state.kidsPorfile}

        />        
      </Grid >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(SwitchProfile));
// Customizable Area End
