import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Dialog,
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { changeLanguageIcon } from "./assets";
// Customizable Area End

import LanguageOptionsController, {
  Props,
  configJSON,
} from "./LanguageOptionsController";

export default class LanguageOptions extends LanguageOptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid
          container
          style={{ marginTop: this.props.isMargin ? "0" : "25px",
          justifyContent:this.props.isMobileMenu ? "inherit":"center",
          marginRight:this.props.isMobileMenu && localStorage.getItem("i18nextLng") === "ar" ? '25px':'0px',
          marginLeft:this.props.isMobileMenu && localStorage.getItem("i18nextLng") !== "ar" ?'25px':'0px'
         }}
          // justifyContent="center"
        >
          <img
            style={webStyle.changeLanguage}
            src={changeLanguageIcon}
            alt="Language"
            onClick={() => this.handleCloseDialog()}
          />
        </Grid>
        <Dialog
          data-test-id="close_"
          PaperProps={{
            style: webStyle.outer_div
          }}
          aria-labelledby="customized-dialog-title"
          open={this.state.isLanguageChange}
          onClose={this.handleCloseDialog}
          closeAfterTransition
          fullWidth={true}
          disableAutoFocus={true}
          style={{
            borderRadius: 8, backdropFilter: "blur(1px)",

          }}
        >
          <Grid container>
            {this.state.languageList.map((_language: {
              id: number,
              code: string,
              name: string
            }) => {
              return (
                <Grid item xs={12} key={_language.id}>
                    <Button
                    fullWidth
                      className="languageChangeButton"
                      style={webStyle.languageTypo}
                      color="primary"
                      onClick={() => this.changeLanguage(_language.code)}
                    >
                    {_language.name}
                  </Button>
                </Grid>
              )

            })}
          </Grid>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  changeLanguage: {
    width: "34.27px",
    height:"26px",
    cursor: "pointer"
  },
  outer_div: {
    background: "linear-gradient(180deg, rgba(7, 7, 13, 0.9) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.9) 100%)",
    width: 400,   
    padding: '30px' 
  },
  languageTypo: {
    textAlign: "center" as const,
    fontWeight: 700,
    fontSize: 18,
    padding: '7px',
    borderRadius: '8px',
    border: '1px solid',
    borderImage : 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%) 1',
    margin: '10px 0', 
  },
  marginLanguage:{
    marginTop: '25px'
  }
};
// Customizable Area End
