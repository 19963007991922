import React, { Component } from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  AppBar,
  withStyles,
  IconButton,
  Typography,
  Hidden,
  Drawer,
  Popover,
  Box,
} from '@material-ui/core';
import { newLogo, notificationIcon, messageImage, searchImage, FavourateImage, podcastImage, movies, tvshows } from "./assets";
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Close from '@material-ui/icons/Close';
import NotificationsWeb from "../../notifications/src/NotificationsWeb.web";
import DrawerController from "./DrawerController.web";
import { withTranslation } from 'react-i18next';
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";
import MesDialog from "../../user-profile-basic/src/shared/MesDialog.web";
import  SettingDialog from "../../user-profile-basic/src/SettingDialog.web";
export const configJSON = require("./config.js");



export const themeCreatStyle: any = (theme: any) => {
  let themePalette = (lightValue: any, darValue: any) => theme.palette.type == 'dark' ? lightValue : darValue;

  return {
    topScrollPaper: {
      justifyContent: theme.direction === "rtl" ? "left" : "right",
    },
    redeem_button_css: {
      boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,

      color: "white",
      opacity: 0.9,
      fontWeight: 700,
      backgroundColor: `rgba(234,67,93, 1)`,
      marginTop: 20,
      fontSize: 18,
      borderRadius: 10,

     
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      "&:hover": {
        backgroundColor: `rgba(234,67,93, 1)`,
        opacity: 0.9,
      },
      "&:disabled": {
        color: `white`,
        opacity: 0.5,
      },
    },
    forrmInput_textfield: {
      background: "#212330",
      color: "#FFFFFF",
      borderRadius: "10px",
      width: "100%",
      fontFamily: "Open sans",

      fontSize: 20,
     
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
      },
    },
    font_size_18: {
      fontSize: 18,
     
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17.5,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16.5,
      },
    },
    font_size_12: {
      fontSize: 12,
     
      [theme.breakpoints.only("md")]: {
        fontSize: 11.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 12,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 11,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 10.5,
      },
    },
    font_size_14: {
      fontSize: 14,
      
      [theme.breakpoints.only("md")]: {
        fontSize: 13.5,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 12.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 13,
      },
    },
    textFiledCss: {
      fontSize: 20,
      lineHeight: 1,
      fontFamily: "Araboto",
      padding: "18px 20px",
      
      color: "#FFFFFF",
      
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
        padding: "14.5px 16px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
        padding: "18px 20px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
        padding: "16px 17px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
        padding: "17px 18.5px",
      },
    },
    font_size_16: {
     
      [theme.breakpoints.only("md")]: {
        fontSize: 15.5,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 14.5,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
      },
    },
    setting_div: {
      justifyContent: "space-between",
      display: "flex",
      padding: "15px 2px 15px 0",
      cursor: 'pointer',
      alignItems: "center",

      [theme.breakpoints.only("lg")]: {
        padding: "15px 2px 15px 0",
      },
      [theme.breakpoints.only("md")]: {
        padding: "14.5px 2px 14.5px 0",
      },
      [theme.breakpoints.only("xs")]: {
        padding: "13px 2px 13px 0",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "14px 2px 14px 0",
      },
    },
    setting_icons: {
      borderRadius: "50%",
      height: 32,
      width: 32,
      justifyContent: "space-between",
      margin: theme.direction === "rtl" ? "0 0 0 15px" : "0 15px 0 0",
      filter: `${theme.palette.type == "dark" ? 'none' : 'invert(1)'}`,
      [theme.breakpoints.only("md")]: {
        width: 31,
        height: 31,
      },
      [theme.breakpoints.only("xs")]: {
        width: 27,
        height: 27,
      },

      [theme.breakpoints.only("lg")]: {
        width: 32,
        height: 32,
      },
      [theme.breakpoints.only("sm")]: {
        width: 29,
        height: 29,
      },
    },
    dialogBackground: {
      // backgroundImage: themePalette(`url(${modalBackground})`, "none"),
      scrollbarColor: "#B7B7B7 transparent",
      height: "100%",
      backgroundSize: "100% 100%",
      background: themePalette("transparent", "white"),
      backgroundRepeat: "no-repeat",
      "&::-webkit-scrollbar": {
        height: 6,
        backgroundColor: "transparent",
        width: 6,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent" ,
      },
      "&::-webkit-scrollbar-thumb": {
        minHeight: 24,
        minWidth: 24,
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
        borderRadius: 6,
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },

      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },
    },
    outer_div: {
      maxWidth: 450,
      background: "transparent"
    },
    font_Size_28: {
      fontSize: 28,
      [theme.breakpoints.only("md")]: {
        fontSize: 26,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 22,
      },

      [theme.breakpoints.only("lg")]: {
        fontSize: 28,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 24,
      },
    },
    font_size_22: {
      fontSize: 22,
      [theme.breakpoints.only("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 22,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 18.5,
      },
    },
    image_div: {
      // maxHeight:150,
      // maxWidth:150,
      margin: "53px 0 30px",
      [theme.breakpoints.only("xs")]: {
        margin: "25px 0 15px",
      },
      [theme.breakpoints.only("md")]: {
        margin: "50px 0 17px",
      },
      [theme.breakpoints.only("lg")]: {
        margin: "53px 0 30px",
      },
      [theme.breakpoints.only("sm")]: {
        margin: "45px 0 23px",
      },
    },
    text_field_div: {
      marginTop: "64px",
      [theme.breakpoints.only("xs")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "56px",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "45px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "64px",
      },
    },
    image_css: {
      borderRadius: "50%",
      [theme.breakpoints.only("md")]: {
        width: 135,
        height: "135px",
      },
      [theme.breakpoints.only("xs")]: {
        width: 100,
        height: "100px",
      },
      [theme.breakpoints.only("sm")]: {
        width: 120,
        height: "120px",
      },
      [theme.breakpoints.up("lg")]: {
        width: 150,
        height: "150px",
      },
      [theme.breakpoints.only("lg")]: {
        width: 150,
        height: "150px",
      },
    },
    user_name_outer_div: {
      padding: 20,
      [theme.breakpoints.only("sm")]: {
        padding: 17,
      },
      [theme.breakpoints.only("xs")]: {
        padding: 15,
      },
      [theme.breakpoints.only("md")]: {
        padding: 19,
      },
      [theme.breakpoints.only("lg")]: {
        padding: 20,
      },
    },
    setting_bottom_div: {
      padding: '0px 30px 30px 30px',
      [theme.breakpoints.only("sm")]: {
        padding: '0px 27px 27px 27px',
      },
      [theme.breakpoints.only("xs")]: {
        padding: '0px 25px 25px 25px',
      },
      [theme.breakpoints.only("md")]: {
        padding: '0px 29px 29px 29px',
      },
      [theme.breakpoints.only("lg")]: {
        padding: '0px 30px 30px 30px',
      },
    },
    details_outer_div: {
      padding: "1px",
      boxShadow: "0px 1px 10px rgba(0,0,0, 0.1)",
      [theme.breakpoints.only("sm")]: {
        borderRadius: 17,
      },
      [theme.breakpoints.only("xs")]: {
        borderRadius: 15,
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: 20,
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: 18,
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: 20,
      },
    },
    details_inner_div: {
      background:
        `${theme.palette.type == "dark" ? "linear-gradient(90deg, rgba(20, 21, 35, 1) 0%, rgba(26, 27, 34, 1) 100%)" : 'white'}`,
      [theme.breakpoints.only("sm")]: {
        padding: "11px",
        borderRadius: 17,
      },  
      [theme.breakpoints.only("xs")]: {
        padding: "15px",
        borderRadius: 15,
      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px",
        borderRadius: 20,
      },
      [theme.breakpoints.only("md")]: {
        padding: "13px",
        borderRadius: 18,
      },
      [theme.breakpoints.only("lg")]: {
        padding: "15px",
        borderRadius: 20,
      },
    },
    shareicons: {
      margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
      height: 36,
      width: 36,
    },
    DialogHeaderContent: {
      margin: theme.direction === "rtl" ? "0 20px 0 0" : "0 0 0 20px",
      flexDirection: "column",
      display: "flex",
    }
  }
};
// Customizable Area End



export class DrawerWeb extends DrawerController {
  // Customizable Area Start
  mobileMenuRender = (selectedUserId: any, classes: any, t: any) => {
    return (
      <>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"top"}
            open={this.state.menuOpen}
            onClose={this.handleMenuToggle}
            classes={{
              paper: classes.drawerPaper1,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            style={{
              backdropFilter: "blur(4px)",
              direction: localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
            }}
          >
            <>
              <Grid container style={{
                justifyContent: 'flex-end'
              }}>
                <IconButton
                  onClick={this.handleMenuToggle}
                  className={classes.closeMenuButton}
                >
                  <Close />
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{
                padding: 16,
              }}>
                <Grid
                  container
                  spacing={4}
                  style={{
                    justifyContent: 'center',
                    // textAlign: 'center'
                  }}>
                  <Grid item xs={12}
                    data-test-id="search"
                    onClick={() => this.handleClickHeading(selectedUserId, "Search")}
                    className={classes.drawerItemList}>
                    <Button
                      color='primary'
                      className={`${classes.headerTextButtons1}`}

                    ><img src={searchImage} alt="icon" className={classes.iconImages}/> {t("header.search")}
                    </Button>
                  </Grid>
                  <Grid container className={classes.dividerStyle}/>
                  <Grid item xs={12} className={classes.drawerItemList}>
                     <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}
                    isMargin={true}      
                    isMobileMenu={true}                                 
                    />
                  </Grid>
                  <Grid container className={classes.dividerStyle}/>
                  <Grid item xs={12}
                    data-test-id="chat"
                    onClick={() => this.handleClickHeading(selectedUserId, "Chat")}
                    className={classes.drawerItemList}>
                    <Button
                      color='primary'
                      className={`${classes.headerTextButtons1}`}

                    ><img src={messageImage} alt="icon" className={classes.iconImages}/>{t("header.messages")}
                    </Button>
                  </Grid>
                  <Grid container className={classes.dividerStyle}/>
                  <Grid item xs={12}
                    className={classes.drawerItemList}>
                    <Button
                      color='primary'
                      data-test-id="notification"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => selectedUserId && this.handleNotification(event)}
                      className={`${classes.headerTextButtons1}`}

                    ><img src={notificationIcon} alt="icon" className={classes.iconImages}/> {t("header.notification")}
                    </Button>
                  </Grid>

                  <Grid container className={classes.dividerStyle}/>
                  <Grid item xs={12}                    
                    className={classes.drawerItemList}>
                    <Button
                    data-test-id="userSetting"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>)=> this.handleUserClick(event)}                    
                      color='primary'
                      className={`${classes.headerTextButtons1}`}
                    >
                      <img alt="Remy Sharp"  className={classes.profileAvatar}
                        src={this.avtarImageSrc()}
                        /> 
                        <p className="single_line" style={{
                          wordBreak: 'break-all',
                          maxWidth: 300,
                          direction: "initial",
                          padding: 0
                        }}> {this.state.userDetails?.name} </p>
                    </Button>
                  </Grid>
                  <Grid container className={classes.dividerStyle}/>
                </Grid>
              </Grid>
            </>
          </Drawer>
        </Hidden>
      </>
    )
  }

  webMenuRender = (selectedUserId: any, classes: any, t: any) => {
    return (
      <>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={this.state.sideBar}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            style={{
              backdropFilter: "blur(4px)",
              direction: localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
            }}
          >
            <>
              <Grid container style={{
                justifyContent: 'flex-end'
              }}>
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <Close />
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{
                padding: 16
              }}>
                <Grid
                  container
                  spacing={4}>
                  <Grid data-test-id="podcast" item xs={12} className={classes.drawerItemList}
                    onClick={() => this.handleClickHeading(selectedUserId, "Podcasts")}>
                    <Button color='secondary' className={`${classes.headerTextButtons1}`}>
                    <img src={podcastImage} alt="icon" className={classes.moviesIcons}/>{t("header.podcast")}</Button>
                  </Grid>
                 <Grid container className={classes.dividerStyleLine}/>

                  {!this.state.isGuestUser && <><Grid data-test-id="favourites" item xs={12} onClick={() => this.handleClickHeading(selectedUserId, "Favourites")} className={classes.drawerItemList}>
                    <Button color='secondary' className={`${classes.headerTextButtons1}`}

                    ><img src={FavourateImage} alt="icon" className={classes.moviesIcons}/> {t("header.favorits")}</Button>
                  </Grid>
                  <Grid container className={classes.dividerStyleLine}/></>}
                  <Grid item xs={12} className={classes.drawerItemList}>
                    <Button data-test-id="tvshows" color='secondary' className={`${classes.headerTextButtons1}`}
                      onClick={() => this.handleClickHeading(selectedUserId, "TvShows")}
                    ><img src={tvshows} alt="icon" className={classes.moviesIcons}/> {t("header.tVShows")}</Button>
                  </Grid>
                 <Grid container className={classes.dividerStyleLine}/>
                  <Grid item xs={12} className={classes.drawerItemList}>
                    <Button data-test-id="movies" color='secondary' className={`${classes.headerTextButtons1}`}
                      onClick={() => this.handleClickHeading(selectedUserId, "Movies")}
                    ><img src={movies} alt="icon" className={classes.moviesIcons}/>{t("header.movies")}</Button>
                  </Grid>
                 <Grid container className={classes.dividerStyleLine}/>
                </Grid>
              </Grid>
            </>
          </Drawer>
        </Hidden>
      </>
    )
  }

  AppBarRender = (selectedUserId: any, classes: any, t: any) => {
    return (
      <>
      <AppBar position="static" className={classes.Header}>
        {this.webMenuRender(selectedUserId, classes, t)}
        <Grid className={classes.Header_left}>
          <img src={newLogo} data-test-id="dashboard" onClick={() => this.handleClickHeading(selectedUserId, "DashboardWeb")} className={classes.headerIcon} alt="avatar" />
          <Grid className={classes.sectionContainer}>
            <Button data-test-id="podcast" disabled={selectedUserId == undefined || selectedUserId == null || selectedUserId == ""}
              onClick={() => this.props.navigation.navigate("Podcasts")} color='secondary' className={`${classes.headerTextButtons} ${classes.font_size_18}`}>
                <img src={podcastImage} alt="icon" className={classes.moviesIcons}/>{t("header.podcast")}</Button>

            {!this.state.isGuestUser && <Button
              data-test-id="favourites"
              disabled={selectedUserId == undefined || selectedUserId == null || selectedUserId == ""}
              color='secondary' className={`${classes.headerTextButtons} ${classes.font_size_18}`}
              onClick={() => this.props.navigation.navigate("Favourites")}
            > <img src={FavourateImage} alt="icon" className={classes.moviesIcons}/> {t("header.favorits")}</Button>}
            <Button
              data-test-id="tvshows"
              disabled={selectedUserId == undefined || selectedUserId == null || selectedUserId == ""}
              color='secondary' className={`${classes.headerTextButtons} ${classes.font_size_18}`}
              onClick={() => this.props.navigation.navigate("TvShows")}
            >  <img src={tvshows} alt="icon" className={classes.moviesIcons}/> {t("header.tVShows")}</Button>
            <Button
              data-test-id="movies"
              disabled={selectedUserId == undefined || selectedUserId == null || selectedUserId == ""}
              color='secondary' className={`${classes.headerTextButtons} ${classes.font_size_18}`}
              onClick={() => this.props.navigation.navigate("Movies")}
            >  <img src={movies} alt="icon" className={classes.moviesIcons}/>{t("header.movies")}</Button>
          </Grid>
          <IconButton color="primary" className={classes.toggleMenu} ref={this.mobileMenuRef} onClick={this.handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Grid>
        {this.mobileMenuRender(selectedUserId, classes, t)}
        <Grid className={classes.Header_right}>
         <img src={messageImage} alt="icon" className={classes.iconImages} onClick={() => this.handleClickHeading(selectedUserId, "Chat")} />
         <img src={searchImage} alt="icon" className={classes.iconImages} onClick={() => this.handleClickHeading(selectedUserId, "Search")} />
         <img src={notificationIcon} alt="icon" className={classes.iconImages} onClick={(event: React.MouseEvent<HTMLImageElement>)=> this.handleNotification(event)} />
         
          <span className={classes.languageSelectContainer}>
          <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}  
                    isMargin={true}                  
                    />
                    </span>
          <img alt="Remy Sharp" data-test-id="nav_userS"
            className={classes.profileAvatar}
            src={this.avtarImageSrc()}
            onClick={(event: React.MouseEvent<HTMLImageElement>)=> this.handleUserClick(event)}
            />
          <Typography className={`${classes.headerTextButtons} ${classes.font_size_18} single_line`} style={{
            wordBreak: 'break-all',
            direction: "initial",
            maxWidth: 190,
            cursor: 'pointer',
            padding: 0
          }} data-test-id="nav_userS1"
          //  onClick={() => this.props.navigation.navigate("UserSetting")}
            color="secondary">{this.state.userDetails?.name} </Typography>
        </Grid>
        <IconButton color="primary" ref={this.searchMenuRef} className={classes.toggleMenu1} onClick={this.handleMenuToggle}>
          <MoreVertIcon />
        </IconButton>
      </AppBar>
    {Boolean(this.state.settingAnchor) && <SettingDialog data-test-id="close" {...this.props} settingAnchor={this.state.settingAnchor} setSettingAnchor={()=>this.setSettingAnchor()} />}
      </>
    )
  }


  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;
    const selectedUserId = localStorage.getItem("selectedUser")

    return (
      <>
      <div className={classes.testclass}>
      <Grid className={classes.dashboardContainer}>
        {this.AppBarRender(selectedUserId, classes, t)}       
        {
          this.state.showNotification === true && <Popover
                        data-test-id="close"
                        anchorEl={this.state.notificationAnchor}
                        open={Boolean(this.state.notificationAnchor)}
                        onClose={() => { this.setNotificationAnchor(null) }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                            className: classes.notificationPopup                            
                        }}
                    >
                      <NotificationsWeb {...this.props} isSmallList={true}/>
                    </Popover>

          
        }

        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{
            height: "auto",
            marginTop: '10px'
          }}
          PaperProps={{
            style: {
              width: '168px',
              maxHeight: '70%',
              padding: "20px 10px 20px 10px",
              borderRadius: 8,
              boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.2)",
              backgroundColor: localStorage.getItem("darkMode") == "false" ? "#fff" : "rgb(16 16 20)"
            },
          }}
        >
          <Box>
            {this.state.categoryList?.filter((_item: any) => {
              if (!_item?.attributes?.name.toLowerCase().includes("tv shows") && !_item?.attributes?.name.toLowerCase().includes("podcast") && !_item?.attributes?.name.toLowerCase().includes("movies")) {
                return _item
              }
            })
              .map((item, index) => {
                return (
                  <Box
                    onClick={this.handleCloseMenu}
                    style={{
                      padding: "5px 0px",
                      borderBottom: "solid 0.5px rgba(63, 82, 109,0.34)",
                      cursor: 'pointer',
                    }}
                    key={item?.id}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        cursor: 'pointer',
                        textAlign: 'center'
                      }}
                      className="font_size_18"
                      color="primary"
                      data-test-id={`category-${index}`}
                      onClick={() => this.onClickCategory(item?.id)}
                    >
                      {item?.attributes?.name}
                    </Typography>
                  </Box>
                )
              })}
          </Box>
        </Popover>
      </Grid>
    </div>
   
    </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  dashboardContainer: {
    width: '100%',
    margin: '0px',
    padding: '0px',
    boxSizing: 'border-box',   
  },
  testclass: {
    display: 'flex' 
  },
  drawerPaper: {
    width: 280,
    background :"#07070E",
    maxWidth: '100%',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'

  },
  moviesIcons:{
    width:"20px",
    height: "20px",
    margin: "0 7px"
  },
  drawerItemList: {
    '&:hover': {
      background: 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)',
      boxShadow: '1px 1px 1px 2px #6869ac'
    }
  },
  drawerPaper1: {
    width: '100%',
    background :"#07070E",
    maxWidth: '100%',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    maxHeight: '100%'

  },
  Header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    padding: '39px 60px',
    background: 'transparent',
    boxShadow: "none",
    [theme.breakpoints.only("xs")]: {
      padding: '15px 20px',
    },
    [theme.breakpoints.only("md")]: {
      padding: '29px 45px',
    },
    [theme.breakpoints.only("sm")]: {
      padding: '22px 30px',
    }
  },
  Header_left: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up("md")]: {
       flex: 1
    },

  },

  headerTextButtons: {
    padding: '0 12px',
    textTransform: 'capitalize',
    fontFamily: "Araboto-Normal",
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0px',
    "&:disabled": {
      color: theme.palette.secondary.main
    }
  },
  dividerStyleLine:{
    background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    height:'1px',
    padding: "0px"
  },
  dividerStyle:{
    background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    height:'1px',
    width: '95%'
  },
  headerTextButtons1: {
    padding: '0 14px 0 14px;',
    textTransform: 'capitalize',
    fontFamily: "Araboto",
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0px',
    "&:hover": {
      background: 'none'
    },
    "&:disabled": {
      color: theme.palette.secondary.main
    }
  },
  selectedTextColor: {
    color: '#EA435D !important',
    fontWeight: 500
  },
  iconImages: {
    margin: '0 10px',
    cursor: "pointer",
    width: 30,
    height: 30,   
  },
  languageSelectContainer:{
    width: '35px',
    margin: "0 10px",
  },
  headerIconButtons: {
    "&:disabled": {
      color: theme.palette.secondary.main
    }
  },
  userName: {
    fontFamily: "Araboto-Normal",
    fontSize: '18px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0px',
    textAlign: 'left',
    paddingLeft: '14px',
  },
  Header_right: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up("md")]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  notificationPopup: {
    width: 410,
    height: 475,
    maxHeight: '90%',
    background: 'none',
    boxShadow: 'none',
    [theme.breakpoints.only("xs")]: {
        width: "90%",
        maxWidth: 390,
    },
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    scrollbarColor: "#B7B7B7 transparent",
    "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        minWidth: 24,
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
        minHeight: 24,
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:focus": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
  },
  "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
  },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)"
},
  headerIcon: {
    width: 70,
    height: 70,
    // margin: "0px 15px",
    cursor: "pointer",
    [theme.breakpoints.only("sm")]: {
      width: 50,
    height: 50,
    // margin: "0px",
      position: 'relative',
      left: '50%',
      right: '50%',
      transform: 'transition(-50% -50%)'
    },
    [theme.breakpoints.only("md")]: {
      width: 63,
    height: 63
  },
    // margin: "0px",
    [theme.breakpoints.only("xs")]: {
      width: 40,
    height: 40,
    // margin: "0px",
      position: 'relative',
      left: '50%',
      right: '50%',
      transform: 'transition(-50% -50%)'
    }
  },
  sectionContainer: {
    display: 'none',
    [theme.breakpoints.up("md")]: {
      display: 'flex'
    },
    margin: theme.direction === "rtl" ? "0 4% 0 0" : "0 0 0 4%"

  },
  menu_icon: {
    margin: '0 15px'
  },
  toggleMenu: {
    display: 'flex',
    position: 'relative',
    left: theme.direction === "rtl" ? 55 : -55,
    right: theme.direction === "rtl" ? "auto" : -55,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: 'none',
    }
  },
  toggleMenu1: {
    display: 'flex',
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: 'none',
    }
  },
  [theme.breakpoints.down("lg")]: {

  },
  font_size_18: {
    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 17.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16.5,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 18,
    },
  },
  [theme.breakpoints.between("sm", "md")]: {
    headerTextButtons: {
      padding: '0 10px 0 10px;',
      fontSize: '14px',
    },   
    userName: {
      fontSize: '16px',
      paddingLeft: '12px',
    },
  },
  profileAvatar: {
    height: '46px',
    cursor: "pointer",
    width: '46px',
    borderRadius: "50%",
    margin: "0px 10px",
    padding: '3px',
    background : "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    [theme.breakpoints.only("xs")]: {
      height: '36px',
    width: '36px',
    
    },
  },
  selectSelect: {
    textTransform: "uppercase" as const,
    padding: "8px",
    paddingRight: "8px !important",
  },
  languageSelect: {
    margin: "0 10px",
    border: theme.palette.type == "dark" ? '1px solid #FFFFFF' : '1px solid #000000',
    borderRadius: 5,
    "&::after": {
      content: "none"
    },
    "&::before": {
      content: "none"
    }
  },
  icon: {
    display: "none"
  },
  active: {
    color: '#EA435D !important'
  }

});
export default withStyles(styles)(withTranslation()(DrawerWeb));

// Customizable Area End